import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import vueScrollto from 'vue-scrollto'
import VueInstagram from 'vue-instagram'
import InstagramEmbed from 'vue-instagram-embed'
import moment from "moment"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// import VueLoaderPlugin  from "vue-loader/lib/plugin"
// import store from './store'


Vue.use(moment)
// Vue.use(VueLoaderPlugin)

Vue.config.productionTip = false
Vue.use(vueScrollto)

Vue.use(VueInstagram)
Vue.component('vue-instagram', VueInstagram)

Vue.use(InstagramEmbed)
Vue.component('instagram-embed', InstagramEmbed)


Vue.config.productionTip = false
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

new Vue({
  router,
  // store,
  axios,
  render: h => h(App),
}).$mount('#app')

// VUE MOMENT
Vue.filter('DateFormat', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMM YYYY')
  }
})