<template>
  <div id="container">
    <div id="preloader" v-show="isLoading"></div>
    
			<div id="wrapper" class="fade-in"  style="min-height:100vh;background-image: url('/files/overlay.png'), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/files/bg.jpg');">
					<div class="row" style="max-width: 1250px; margin-right: auto; margin-left:auto;">
            <div class="col-12 text-center my-5">
              <img src="../assets/img/logo.png">
            </div>
            <div class="col-12">
              <div class="mx-5 px-4 pt-2 pb-2 mb-5 card" >
                <div class="row">
                  <div class="col-12 mt-4">
                    <span style="font-size: 25px; font-weight: bold;">{{data.title}}</span>
                  </div>
                  <div class="col-12">
                    <span style="font-size: 13px; color: grey;">Published on: {{data.creation | DateFormat}}</span>
                  </div>
                  <div class="mt-4 text-center" :class="data.youtube_url == null ? 'col-md-12': 'col-md-6'" v-if="data.image">
                    <img :src="data.image" style="max-width: 500px; margin-right: auto; margin-left: auto;" class="card" width="100%"/>
                  </div>
                  <div class="col-md-6 mt-4" :class="data.image == null ? 'col-md-12': 'col-md-6'"  v-if="data.youtube_url">
                    <iframe allowfullscreen class="card" width="100%" height="222px" :src="data.youtube_url"></iframe>
                  </div>
                  <div class="col-12 mt-4">
                    <span v-html="data.article"></span>
                  </div>
                  <div class="col-12 mt-5">
                    <div class="text-center" style="font-size:12px;">
                      <p>&copy; 2020 <a href="https://www.instagram.com/xeoncode.id/"> Xeon Code</a></p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
			</div>
  </div>
</template>
      

<script>
import apiRequest from '@/helper/apiRequest.js'

export default {
  name: 'Home',
  data () {
    return {
      isLoading: true,
      data: {},
      page : 0,
      isLastPage: false,
    }
  },
  created () {
    this.fetchData()
  },
  mounted () {
    
  },
  methods: {
    async fetchData(){
        this.isLoading = true
        var body = {
            "doctype" : "Promo",
            "name": this.$route.params.id
        }
        var response = await apiRequest.getDoc(body)
        console.log(response)
        if(response.data != null){
          this.data = response.data
        }
        this.isLoading = false
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
