<template>
  <div id="container">
    <div id="preloader" v-show="isLoading"></div>
    
			<div id="wrapper" class="fade-in"  style="min-height:100vh;background-image: url('/files/overlay.png'), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/files/bg.jpg');">
					<div class="row" style="max-width: 1250px; margin-right: auto; margin-left:auto;">
            <div class="col-12 text-center my-5">
              <img src="../assets/img/logo.png">
            </div>
            <div class="col-12">
              <div class="mx-5 px-4 pt-2 pb-2 mb-5 card" >
                <div class="row">
                  
                  <div class="col-12 my-5">
                    <center><h2><b>{{data.event_name}}</b></h2>
                    <h4>{{data.event_date | DateFormat}} - {{data.event_location}}</h4></center>	
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6" v-for="(data,idx) in data.photo" :key="idx">
                    <div class="card mb-4" @click="selectedImg(data)" data-toggle="modal" data-target="#detail" style="width: 100%; cursor: pointer;">
                      <img id="productImage" class="card-img-top" style="object-fit: cover;" :style="'height: ' + imageHeight + 'px'" :src="data.photo" alt="" v-if="data.photo">
                      <iframe allowfullscreen id="productImage" style="object-fit: cover; border:none;" class="card-img-top" :style="'height: ' + imageHeight + 'px'" :src="data.youtube_url" alt="" v-else/>
                      <div class="card-body">
                        <h5 class="m-0" style="font-size: 15px;"><b>{{data.description}}</b></h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-5">
                    <div class="text-center" style="font-size:12px;">
                      <p>&copy; 2020 <a href="https://www.instagram.com/xeoncode.id/"> Xeon Code</a></p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
			</div>

      <div class="modal fade" id="detail">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">{{selected.description}}</h4>
					<a type="button" class="close" data-dismiss="modal" aria-hidden="true">×</a>
				</div>
					<div class="modal-body text-left" style="overflow-y: auto;">
						<img :src="selected.photo" class="card" style="max-width: 100%; border:none;margin-right: auto;margin-left: auto;" v-if="selected.photo"/>
            <iframe allowfullscreen class="card" style="width: 100%; height:400px; border:none;margin-right: auto;margin-left: auto;" :src="selected.youtube_url" v-else/>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
      

<script>
import apiRequest from '@/helper/apiRequest.js'
import helper from '@/helper/global.js'

export default {
  name: 'Home',
  data () {
    return {
		isLoading: true,
		data: {},
		imageHeight: 0,
		selected: {}
    }
  },
  created () {
    this.fetchData()
    window.addEventListener('resize', this.resize)
    setTimeout(() => this.resize(), 500)
    // this.resize()
  },
  mounted () {
  },
  methods: {
    resize() {
      this.imageHeight = helper.resizeElement('productImage', 1)
    },
    async fetchData(){
        this.isLoading = true
        var body = {
			"doctype" : "Gallery",
            "name": this.$route.params.id
        }
        var response = await apiRequest.getDoc(body)
        console.log(response)
        if(response.data != null){
			this.data = response.data
        }
        this.isLoading = false
	},
	selectedImg(data){
		this.selected = data
	}
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
