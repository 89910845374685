
import axios from 'axios'

var apiRequest = {}

apiRequest.getToken = ()=> {
  return 'token 39d117014d3b106:994b2ed69946e38'
}

apiRequest.getIpAddress = async () => {
  var url = 'https://ipapi.co/json/'
  var response = await axios.get(url,{
    headers: {
      'content-type': 'application/json'
    }
  })
      .then(response => {
        return {'data': response.data}
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.getDoc = async (body) => {
  var url = '/api/method/nungkymusicschool.api.getDoc'
  var response = await axios.post(url, body,{
    headers: {
      'content-type': 'application/text',
      'authorization': apiRequest.getToken()
    }
  })
      .then(response => {
        var rsp = apiRequest.checkResponse(response,url)
        console.log(rsp)
        return rsp
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.getList = async (body) => {
  var url = '/api/method/nungkymusicschool.api.getList'
  var response = await axios.post(url, body,{
    headers: {
      'content-type': 'application/text',
      'authorization': apiRequest.getToken()
    }
  })
      .then(response => {
        var rsp = apiRequest.checkResponse(response,url)
        console.log(rsp)
        return rsp
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.getWebsite = async (body) => {
  var url = '/api/method/nungkymusicschool.api.getWebsite'
  var response = await axios.post(url, body,{
    headers: {
      'content-type': 'application/text',
      'authorization': apiRequest.getToken()
    }
  })
      .then(response => {
        var rsp = apiRequest.checkResponse(response,url)
        console.log(rsp)
        return rsp
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.createDoc = async (body) => {
  var url = '/api/method/nungkymusicschool.api.createDoc'
  var response = await axios.post(url, body,{
    headers: {
      'content-type': 'application/text',
      'authorization': apiRequest.getToken()
    }
  })
      .then(response => {
        var rsp = apiRequest.checkResponse(response,url)
        console.log(rsp)
        return rsp
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.createOrUpdateDoc = async (body) => {
  var url = '/api/method/nungkymusicschool.api.createOrUpdateDoc'
  var response = await axios.post(url, body,{
    headers: {
      'content-type': 'application/text',
      'authorization': apiRequest.getToken()
    }
  })
      .then(response => {
        var rsp = apiRequest.checkResponse(response,url)
        console.log(rsp)
        return rsp
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.postSubscribe = async (body) => {
  var url = '/api/method/nungkymusicschool.api.postSubscribe'
  var response = await axios.post(url, body,{
    headers: {
      'content-type': 'application/text',
      'authorization': apiRequest.getToken()
    }
  })
      .then(response => {
        var rsp = apiRequest.checkResponse(response,url)
        console.log(rsp)
        return rsp
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.sendEmailContactUs = async (body) => {
  var url = '/api/method/nungkymusicschool.api.sendEmailContactUs'
  var response = await axios.post(url, body,{
    headers: {
      'content-type': 'application/text',
      'authorization': apiRequest.getToken()
    }
  })
      .then(response => {
        var rsp = apiRequest.checkResponse(response,url)
        console.log(rsp)
        return rsp
      })
      .catch(error => {
        console.log({'error':error})
        return {'error':error}
      })
  return response
};

apiRequest.checkResponse = (response, url) => {
  console.log(response.status+': '+url)
  if (response.status == 200){
    var data = response.data.message
    if (data.code == 200){
      return {'data': data.data}
    }
    else {
      return {'error': data.error}
    }
  }
  else {
    return {'error': 'Server Error'}
  }
}

export default apiRequest;