<template>
  <div id="container">
    <div id="preloader" v-show="isLoading"></div>
    
			<div id="wrapper" class="fade-in"  style="min-height:100vh;background-image: url('/files/overlay.png'), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/files/bg.jpg');">
					<div class="row" style="max-width: 1250px; margin-right: auto; margin-left:auto;">
            <div class="col-12 text-center my-5">
              <img src="../assets/img/logo.png">
            </div>
            <div class="col-12">
              <div class="mx-5 px-4 pt-2 pb-2 mb-5 card" >
                <div class="row">
                  <div class="col-12 mb-3">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/'" class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Website</router-link>
                      </li>
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/promo'" class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Promo</router-link>
                      </li>
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/news'" class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">News</router-link>
                      </li>
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/gallery'" class="nav-link active" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Gallery</router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12">
                      <table class="table">
                        <tr>
                          <td><center><b>#</b></center></td>
                          <td><center><b>Nama Acara</b></center></td>
                          <td><center><b>Action</b></center></td>
                        </tr>
                      <tr v-for="(data,index) in data" :key="index" class="text-left">
                        <td><center>{{index + 1}}</center></td>
                        <td><center>{{data.event_name}}</center></td>
                        <td><center><router-link :to="'/gallery/'+data.name"><button class="btn btn-primary">Foto Acara</button></router-link></center></td>
                      </tr>

                      </table>
                  </div>
                  <div class="text-center posts col-12" style="background-color: #ffffff;
                      position: relative;
                      margin: 0 auto;
                      width: calc(100% - 4rem);
                      max-width: 95rem;
                      z-index: 2;" v-if="!isLastPage">
                    <button  class="btn btn-outline-primary" style="font-size: 12px; margin: 20px;" @click="fetchData()">Load More</button>
                  </div>
                  <div class="col-12 mt-5">
                    <div class="text-center" style="font-size:12px;">
                      <p>&copy; 2020 <a href="https://www.instagram.com/xeoncode.id/"> Xeon Code</a></p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
			</div>
  </div>
</template>
      

<script>
import apiRequest from '@/helper/apiRequest.js'
import helper from '@/helper/global.js'

export default {
  name: 'Home',
  data () {
    return {
      isLoading: true,
      data: [],
      page : 0,
      isLastPage: false,
      imageHeight: 0
    }
  },
  created () {
    window.addEventListener('resize', this.resize)
    this.fetchData()
  },
  mounted () {
    this.resize()
  },
  methods: {
    resize() {
      this.imageHeight = helper.resizeElement('productImage', 1)
    },
    async fetchData(){
        this.isLoading = true
        var body = {
            "doctype" : "Gallery",
            "page": this.page
        }
        var response = await apiRequest.getList(body)
        console.log(response)
        if(response.data != null){
            if (this.page == 0) {
                this.data = []
            }
            for (let i in response.data){ 
                var data = response.data[i]
                this.data.push(data)
            }
            this.page += 20
        }
        if (response.data.length == 0) {
            this.isLastPage = true
        }
        if (response.data.length < 20) {
            this.isLastPage = true
        }
        this.isLoading = false
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
