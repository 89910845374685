<template>
  <div id="container">
    <div id="preloader" v-show="isLoading"></div>
    
			<div id="wrapper" class="fade-in"  style="min-height:100vh;background-image: url('/files/overlay.png'), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/files/bg.jpg');">
					<div class="row" style="max-width: 1250px; margin-right: auto; margin-left:auto;">
            <div class="col-12 text-center my-5">
              <img src="../assets/img/logo.png">
            </div>
            <div class="col-12">
              <div class="mx-5 px-4 pt-2 pb-2 mb-5 card" >
                <div class="row">
                  <div class="col-12 mb-3">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/'" class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Website</router-link>
                      </li>
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/promo'" class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Promo</router-link>
                      </li>
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/news'" class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">News</router-link>
                      </li>
                      <li class="nav-item" role="presentation">
                        <router-link :to="'/gallery'" class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Gallery</router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-4 col-md-6" v-for="(data, idx) in data" :key="idx" style="max-height: 540px; cursor: pointer;" @click="goToDetail(data.name)">
                      <div class="card mb-3" style="max-width: 540px;">
                        <div class="row no-gutters">
                          <div class="col-md-4">
                            <img :src="data.image" style="object-fit: cover;" class="card-img" id="productImage" :style="imageHeight > 0 ? 'height: ' + imageHeight + 'px' : ''">
                          </div>
                          <div class="col-md-8">
                            <div class="card-body">
                              <h5 class="line-clamp-3 my-0" style="font-size: 15px;"><b>{{data.title}}</b></h5>
                              <p class="card-text my-0" style="font-size: 12px;"><small class="text-muted">{{data.creation | DateFormat}}</small></p>                              
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="text-center posts col-12" style="background-color: #ffffff;
                      position: relative;
                      margin: 0 auto;
                      width: calc(100% - 4rem);
                      max-width: 95rem;
                      z-index: 2;" v-if="!isLastPage">
                    <button  class="btn btn-outline-primary" style="font-size: 12px; margin: 20px;" @click="fetchData()">Load More</button>
                  </div>
                  <div class="col-12 mt-5">
                    <div class="text-center" style="font-size:12px;">
                      <p>&copy; 2020 <a href="https://www.instagram.com/xeoncode.id/"> Xeon Code</a></p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
			</div>
  </div>
</template>
      

<script>
import apiRequest from '@/helper/apiRequest.js'
import helper from '@/helper/global.js'

export default {
  name: 'Home',
  data () {
    return {
      isLoading: true,
      data: [],
      page : 0,
      isLastPage: false,
      imageHeight: 0
    }
  },
  created () {
    window.addEventListener('resize', this.resize)
    setTimeout(() => this.resize(), 500)
    // this.resize()
    this.fetchData()
  },
  mounted () {
    // setTimeout(this.resize(), 200)
    // this.resize()
  },
  methods: {
    resize() {
      this.imageHeight = helper.resizeElement('productImage', 1)
      // alert(this.imageHeight)
    },
    async fetchData(){
        this.isLoading = true
        var body = {
            "doctype" : "Promo",
            "filters": [['is_publish','=',1]],
            "page": this.page
        }
        var response = await apiRequest.getList(body)
        console.log(response)
        if(response.data != null){
            if (this.page == 0) {
                this.data = []
            }
            for (let i in response.data){ 
                var data = response.data[i]
                this.data.push(data)
            }
            this.page += 20
        }
        if (response.data.length == 0) {
            this.isLastPage = true
        }
        if (response.data.length < 20) {
            this.isLastPage = true
        }
        this.isLoading = false
    },
    goToDetail(name) {
      location.href = '/#/promo/'+name
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
