<template>
  <div id="container">
    <div id="preloader" v-show="isLoading"></div>
    <!--==========================
    Hero Section
    ============================-->
    <div id="hero">
        <div class="hero-container">
        <div class="wow fadeIn">
            <div class="hero-logo">
            <img class="" src="../assets/img/logo.png" alt="Imperial">
            </div>

            <h1>Welcome to Nungky Music School</h1>
            <h2>We have <span class="rotating">{{ alatMusikDipilih }}</span> Class</h2>
            <div class="actions">
            <a href="#about" class="btn-get-started">Get Strated</a>
            <router-link to="/promo" class="btn-services">Promo &amp; News</router-link>
            </div>
        </div>
        </div>
    </div>

     <!--==========================
      Header Section
      ============================-->
  <header id="header" style="width: 100%; position: sticky; top: 0px; z-index: 50; background: rgba(0, 0, 0, 0.85);">
    <div class="container">

      <div id="logo" class="pull-left">
        <a href="#hero"><img src="../assets/img/logo.png" alt="" title=""/></a>
      </div>

      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li><a href="#hero">Home</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#subscribe">Hot Promo</a></li>
          <li><a href="#services">Our Class</a></li>
          <li><a href="#portfolio">Activities</a></li>
          <li><a href="#testimonials">Testimonials</a></li>
          <li><a href="#contact">Contact Us</a></li>
          <li><router-link to="/promo">Promo &amp; News</router-link></li>
        </ul>
      </nav>
      <!-- #nav-menu-container -->
    </div>
  </header>
  <!-- #header -->

  <!--==========================
  About Section
  ============================-->
  <div id="about">
    <div class="container wow fadeInUp">
      <div class="row">
        <div class="col-md-12">
          <h3 class="section-title" style="margin-top: 50px;">Nungky Music School</h3>
          <div class="section-title-divider"></div>
          <p class="section-description">{{websiteData.address}}. Telp. {{websiteData.phone}}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 about-content text-left" style="border-radius: 10px;" >
          <img :src="websiteData.intro_photo" style="width: 100%; height: 320px; object-fit: cover; border-radius: 10px;" class="card" v-if="websiteData.intro_type == 'Photo'" />
          <iframe allowfullscreen style="border-radius: 10px;" class="card" width="100%" height="320px" :src="websiteData.intro_youtube_url" v-else></iframe>
        </div>
        <div class="col-md-6 about-content text-left" >
          <h2 class="about-title">{{ websiteData.title }}</h2>
          <p class="about-text" v-html="websiteData.description">
          </p>
        </div>
      </div>
    </div>
  </div>

  <!--==========================
  Subscrbe Section
  ============================-->
  <div id="subscribe">
    <div class="container wow fadeInUp">
          <center><h3 class="subscribe-title" style="margin-top: 50px; margin-bottom: 60px;">HOT PROMO</h3></center>
        <div class="row"> 
          <div class="col-md-6" v-if="promoData.image" :class="promoData.youtube_url ? '': 'offset-md-3'">
            <img style="border-radius: 10px;" :src="promoData.image" alt="" width="100%">
          </div>
          <div class="col-md-6" v-if="promoData.youtube_url" :class="promoData.image ? '': 'offset-md-3'">
            <iframe allowfullscreen style="border-radius: 10px;" class="card" width="100%" height="320px" :src="promoData.youtube_url"></iframe>
          </div>
          <div class="text-left mt-5" :class="'col-md-12'">
            <h2 class="subscribe-title">{{promoData.title}}</h2>
            <p class="subscribe-text" v-html="promoData.article"></p>
          </div>
        </div>
          <br>
          <p class="subscribe-text">Untuk mengetahui PROMO dan BERITA ter-update tentang Nungky Music School, isi email anda dan klik tombol subscribe.</p>
                <div class="row">
                    <div class="form-group col-12">
                        <input type="email" v-model="email_subscriber"  class="form-control" placeholder="Your e-Mail" />
                        <div class="validation"></div><br>
                        <p class="text-center card" :style="'color: white; background-color: '+email_subscriber_validation_color+'; padding: 10px;'" v-show="email_subscriber_validation"><b>{{email_subscriber_validation}}</b></p>
                        <div class="text-center" @click="postSubscribe"><button class="subscribe-btnn" >Subscribe Now</button></div>
                    </div>
                </div>
    </div>
  </div>

  <!--==========================
  Services Section
  ============================-->
  <div id="services">
    <div class="container wow fadeInUp">
      <div class="row">
        <div class="col-md-12">
          <h3 class="section-title" style="margin-top: 50px;">Our Class</h3>
          <div class="section-title-divider"></div>
          <p class="section-description">Klik Gambar untuk melihat detail kurikulum kelas.</p>
        </div>
      </div>
      <div class="row text-left" v-for="(data, index) in websiteData.class_type" :key="'type'+index">
        <div class="col-lg-12">
            <div class="service-icon"><a href="" data-toggle="modal" data-target="#detail" @click="modalData = data" ><img :src="data.image" alt="" class="rounded-circle" height="50" width="50"></a></div>
            <h3 class="service-title"><a href="#" data-toggle="modal" data-target="#detail" @click="modalData = data" >{{data.type}}</a></h3>
            <h4 class="service-title">{{ data.short_description }}</h4><br>
            <div class="row" style="margin-left: 60px;">
                <div class="col-lg-12 service-item" v-for="(datas, idx) in data.list_category" :key="'category'+data.type+idx" >
                    <div class="service-icon"><a href="#" data-toggle="modal" data-target="#detail" @click="modalData = datas" ><img :src="datas.image" alt="" class="rounded-circle" height="50" width="50"></a></div>
                    <h4 class="service-title"><a href="#" data-toggle="modal" data-target="#detail" @click="modalData = datas" >{{datas.category}}</a></h4>
                    <p class="service-description">{{datas.short_description}}</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <!--==========================
  Porfolio Section
  ============================-->
  <div id="portfolio">
    <div class="container wow fadeInUp">
      <div class="row">
        <div class="col-md-12">
          <h3 class="section-title" style="margin-top: 50px;">Activities</h3>
          <div class="section-title-divider"></div>
          <p class="section-description">This is our recent activities</p>
        </div>
      </div>
      
      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3 card no-border" v-for="(data, idxx) in websiteData.list_activities" :key="'foto'+idxx">
          <a class="portfolio-item card no-border" :style="'background-image: url('+data.image+');'">
            <div class="details p-3">
              <h4>{{data.event}}</h4>
              <span>{{data.event_date | DateFormat}}</span>
            </div>
          </a>
        </div>

      </div>
    </div>
  </div>

  <!--==========================
  Testimonials Section
  ============================-->
  <div id="testimonials">
    <div class="container wow fadeInUp">
      <div class="row">
        <div class="col-md-12">
          <h3 class="section-title" style="margin-top: 50px;">Testimonials</h3>
          <div class="section-title-divider"></div>
          <p class="section-description">Berikut beberapa testimoni dari murid dan orang tua murid Nungky Music School.</p>
        </div>
      </div>

      <div class="row" v-for="(data,index) in websiteData.list_testimonial" :key="'tetsi'+index">
        <div class="col-md-12">
          <div class="quote">
            <b><img src="/files/quote_sign_left.png" alt=""></b> {{data.testimonial}} <small><img src="/files/quote_sign_right.png" alt=""></small>
            <br><br><center><p>~{{data.name1}}~</p></center>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!--==========================
  Contact Section
  ============================-->
  <div id="contact">
    <div class="container wow fadeInUp">
      <div class="row">
        <div class="col-md-12">
          <h3 class="section-title" style="margin-top: 50px;">Contact Us</h3>
          <div class="section-title-divider"></div>
          <p class="section-description">Untuk tanya-tanya seputar Nungky Music School dapat mengisi form dibawah<br> Kami akan mengirim jawaban ke e-mail anda. Terimakasih.</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 offset-md-2 col-md-push-2 text-left">
          <div class="info">
            <div>
              <i class="fa fa-map-marker"></i>
              <p>{{websiteData.address}}</p>
            </div>

            <div>
              <i class="fa fa-envelope"></i>
              <p>{{websiteData.email}}</p>
            </div>

            <div>
              <i class="fa fa-phone"></i>
              <p>{{websiteData.phone}}</p>
            </div>

          </div>
        </div>

        <div class="col-md-5 col-md-push-2">
          <div class="form">
              <div class="form-group">
                <input type="text" name="name" class="form-control" v-model="name" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required="" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <input type="number" name="hp" class="form-control" v-model="phone" id="name" placeholder="Your Phone Number" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required="" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <input type="email" class="form-control" name="email" v-model="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" required=""/>
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" name="subject" v-model="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" required=""/>
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <textarea class="form-control" name="message" rows="5" v-model="message" data-rule="required" data-msg="Please write something for us" placeholder="Message" required=""></textarea>
                <div class="validation"></div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" :checked="isWantSubscribe" @click="changeIsWantSubscribe()">
                <label class="form-check-label" for="defaultCheck1">
                  Saya ingin subscribe Nungky Music School
                </label>
              </div>
              <p class="text-center card mt-3" :style="'color: white; background-color: '+contactus_validation_color+'; padding: 10px;'" v-show="contactus_validation"><b>{{contactus_validation}}</b></p>       
              <center><button class="submit mt-2" @click="postContactUs">Send Message</button></center>

                <!-- <instagram-embed
                  :url="'https://www.instagram.com/p/CBaqIihpeNh/?utm_source=ig_web_button_share_sheet'"
                  :hide-caption = "true"
                /> -->


          </div>
        </div>

      </div>
    </div>
  </div>

  <!--==========================
  Footer
  ============================-->
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="credits m-0 p-0">
            <strong>Develop by: <a href="https://www.instagram.com/xeoncode.id/">Xeon Code</a></strong> | &copy; Copyright <strong>Imperial Theme</strong>. All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- #footer -->


    <div class="modal fade" id="detail" tabindex="1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-lg modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><b>{{modalData.category || modalData.type}}</b></h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
                <div class="modal-body text-left" style="overflow-y: auto;">
                    <p align="justify" v-html="modalData.long_description"></p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
      

<script>
import apiRequest from '@/helper/apiRequest.js'
// import Vue from 'vue'
// import getMAC from 'macaddress'
// import { set } from 'vue/types/umd'

export default {
  name: 'Home',
  data () {
    return {
        websiteData: {},
        promoData: {},
        modalData: {},
        isLoading: false,
        email_subscriber: '',
        email_subscriber_validation: '',
        email_subscriber_validation_color: 'red',
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: '',
        contactus_validation: '',
        contactus_validation_color: 'red',
        alatMusik: 'Piano, Keyboard, Electone, Biola, Gitar, Drum, Saxophone, Vocal, Kiddy Fantasy, NMS Go, International',
        alatMusikDipilih: 'International',
        indexAlatMusik: 0,
        interval: null,
        isWantSubscribe: true
    }
  },
  created () {
    // Vue.loadScript('/assets/lib/jquery/jquery.min.js')
    // Vue.loadScript('/assets/lib/bootstrap/js/bootstrap.min.js')
    // Vue.loadScript('/assets/lib/superfish/hoverIntent.js')
    // Vue.loadScript('/assets/lib/superfish/superfish.min.js')
    // Vue.loadScript('/assets/lib/morphext/morphext.min.js')
    // Vue.loadScript('/assets/lib/wow/wow.min.js')
    // Vue.loadScript('/assets/lib/stickyjs/sticky.js')
    // Vue.loadScript('/assets/lib/easing/easing.js')
    this.fetchData()
    this.getIpAddress()
    // macaddress.all(function (err, all) {
    //   console.log(JSON.stringify(all, null, 2));
    // });
    
    this.interval = setInterval(this.changeAlatMusik, 1500)
  },
  methods: {
    changeIsWantSubscribe(){
      this.isWantSubscribe = !this.isWantSubscribe
    },
    changeAlatMusik () {
        let arr = this.alatMusik.split(', ')

        this.alatMusikDipilih = arr[this.indexAlatMusik]

        this.indexAlatMusik += 1
        this.indexAlatMusik %= arr.length
    },
    async fetchData() {
        this.isLoading = true
        var body = {
            "doctype" : "Website"
        }
        var response = await apiRequest.getWebsite(body)
        console.log(response)
        if(response.data != null){
            this.websiteData = response.data
            if(this.websiteData.promo.length > 0){
                this.promoData = this.websiteData.promo[0]
            }
        }
        this.isLoading = false
    },
    async postSubscribe() {
        this.email_subscriber_validation = ""
        if (this.email_subscriber == ""){
            this.email_subscriber_validation = "Silahkan isi email"
            this.email_subscriber_validation_color = "red"
        }
        else {
            this.isLoading = true
            var body = {
                "doctype" : "Subscriber",
                "is_subscribe" : 1,
                "email" : this.email_subscriber
            }
            var response = await apiRequest.postSubscribe(body)
            console.log(response)
            if(response.data != null){
                this.email_subscriber_validation = "Subscribe Sukses"
                this.email_subscriber_validation_color = "green"
            }
            else{
                if(response.error.includes("Duplicate entry")){
                    this.email_subscriber_validation = "Email sudah terdaftar, silahkan input email lain"
                }
                else{
                    this.email_subscriber_validation = response.error
                }
                this.email_subscriber_validation_color = "red"
            }
            this.isLoading = false
        }
    },
    async postContactUs() {
        if (this.name == ''){
            this.contactus_validation = "Silahkan isi nama."
            this.contactus_validation_color = "red"
        }
        else if (this.phone == ''){
            this.contactus_validation = "Silahkan isi nomor telepon."
            this.contactus_validation_color = "red"
        }
        else if (this.email == ''){
            this.contactus_validation = "Silahkan isi email."
            this.contactus_validation_color = "red"
        }
        else if (this.subject == ''){
            this.contactus_validation = "Silahkan isi judul pertanyaan."
            this.contactus_validation_color = "red"
        }
        else if (this.message == ''){
            this.contactus_validation = "Silahkan isi deskripsi pertanyaan."
            this.contactus_validation_color = "red"
        }
        else {
            this.isLoading = true
            var body = {
                "name" : this.name,
                "phone" : this.phone,
                "email" : this.email,
                "subject" : this.subject,
                "message" : this.message
            }
            var response = await apiRequest.sendEmailContactUs(body)
            console.log(response)
            if(response.data != null){
                if (this.isWantSubscribe) {
                  this.isLoading = true
                  var bodys = {
                      "doctype" : "Subscriber",
                      "is_subscribe" : 1,
                      "email" : this.email
                  }
                  var responses = await apiRequest.postSubscribe(bodys)
                  console.log(responses)
                  this.isLoading = false
                }
                this.contactus_validation = "Pertanyaan berhasil dikirim. Anda akan segera kami hubungi via email / whatsapp."
                this.contactus_validation_color = "green"
            }
            else{
                this.contactus_validation = response.error
                this.contactus_validation_color = "red"
            }
            this.isLoading = false
        }
    },
    async getIpAddress() {
        var response = await apiRequest.getIpAddress()
        console.log(response)
        if(response.data != null){
          this.postIpAddress(response.data)
        }
    },
    async postIpAddress(body) {
        body["doctype"] = "Website User Log"
        body["name"] = body["ip"]
        var response = await apiRequest.createOrUpdateDoc(body)
        console.log(response)
        if(response.data != null){
          console.log("POST IP ADDRESS", response.data) 
        }
    },
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
