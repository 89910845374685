import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'
import Promo from './components/PromoNew.vue'
import PromoDetail from './components/PromoDetail.vue'
import News from './components/NewsNew.vue'
import NewsDetail from './components/NewsDetail.vue'
import Gallery from './components/GalleryNew.vue'
import GalleryDetail from './components/GalleryDetailNew.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '*',
      name: 'home',
      component: Home
    },
    {
      path: '/promo',
      name: 'promo',
      component: Promo
    },
    {
      path: '/promo/:id',
      name: 'promos',
      component: PromoDetail
    },
    {
      path: '/news',
      name: 'news',
      component: News
    },
    {
      path: '/news/:id',
      name: 'news',
      component: NewsDetail
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: Gallery
    },
    {
      path: '/gallery/:id',
      name: 'gallerys',
      component: GalleryDetail
    },
  ],
})
