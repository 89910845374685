var helper = {}

helper.resizeElement = (elementID, ratio) => {
    var el = document.getElementById(elementID)

    var height = 0
    console.log('element #' + elementID)
    if (el) {
        height = el.offsetWidth * ratio
        console.log('Element #' + elementID +  ' width: ' + el.offsetWidth)
    }

    return height
}

helper.getElementHeight = (elementID) => {
    var el = document.getElementById(elementID)

    var height = 0
    if (el) {
        height = el.offsetHeight
    }

    return height
}

export default helper;